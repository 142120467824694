import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
  ApiResponseType,
  IForgetPassword,
  IForgetPasswordResponse,
  ILogin,
  ILoginResponse,
  IOtpVerification,
  IOtpVerificationResponse,
  IPasswordVerify,
  IRegisterUser, IResendOTP,
  IResetPassword,
  IResetPasswordResponse,
  IUser, TUser
} from './ContextType';
import axiosInstance from '../api';

type IAuthContext = {
  registerUser: (data: IRegisterUser) => Promise<ApiResponseType<IUser>>;
  forgetPassword: (data: IForgetPassword) => Promise<ApiResponseType<IForgetPasswordResponse>>;
  loginUser: (data: ILogin) => Promise<ApiResponseType<ILoginResponse>>;
  otpVerification: (data: IOtpVerification) => Promise<ApiResponseType<IOtpVerificationResponse>>;
  resendOtp: (data: IResendOTP) => Promise<ApiResponseType<string>>;
  resetPassword: (data: IResetPassword) => Promise<ApiResponseType<IResetPasswordResponse>>;
  logoutUser: () => Promise<ApiResponseType<IResetPasswordResponse>>;
  passwordVerification: (data: IPasswordVerify) => Promise<ApiResponseType<IResetPasswordResponse>>;
  isLoading: boolean;
  user: TUser;
  getUserProfile: () =>  Promise<ApiResponseType<TUser>>
};

const AuthContext = createContext<IAuthContext | undefined>(undefined);

type IAuthProviderProps = {
  children: ReactNode;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useTheme must be used within a AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [user, setUser] = useState<TUser>({
    id: "",
    name: '',
    parentId: "",
    username: '',
    email: "",
    phone: '',
    countryCode: '',
    is_2fa_enabled: false,
    isPhoneVarified: false,
    roles: [],
    created_at: ""
  })
  const registerUser = async (data: any): Promise<ApiResponseType<IUser>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('auth/register', data);
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false)
      return {
        success: false,
        message: 'Please Try Again'
      }
    } finally {
      setIsLoading(false)
    }
  }

  const forgetPassword = async (data: IForgetPassword): Promise<ApiResponseType<IForgetPasswordResponse>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('auth/password/forget', data);
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false)
      return {
        success: false,
        message: 'This email is not registered with us'
      }
    } finally {
      setIsLoading(false)
    }
  }

  const loginUser = async (data: ILogin): Promise<ApiResponseType<ILoginResponse>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('auth/login', data);
      localStorage.setItem('access_token', response.data.result.access_token);
      localStorage.setItem('refresh_token', response.data.result.refresh_token);
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false)
      return {
        success: false,
        message: e.response.data.message
      }
    } finally {
      setIsLoading(false)
    }
  }

  const otpVerification = async (data: IOtpVerification): Promise<ApiResponseType<IOtpVerificationResponse>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('auth/verify/email', data);
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false)
      return {
        success: false,
        message: e.response.data.message
      }
    } finally {
      setIsLoading(false)
    }
  }

  const resendOtp = async (data: IResendOTP): Promise<ApiResponseType<string>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('auth/register/otp/resend', data);
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false)
      return {
        success: false,
        message: 'Unable to  Resend Otp'
      }
    } finally {
      setIsLoading(false)
    }
  }

  const resetPassword = async (data: IResetPassword): Promise<ApiResponseType<IResetPasswordResponse>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('auth/password/reset', data);
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false)
      return {
        success: false,
        message: e.response?.data?.message || 'Please try again!'
      }
    } finally {
      setIsLoading(false)
    }
  }

  const passwordVerification = async (data: IPasswordVerify): Promise<ApiResponseType<IResetPasswordResponse>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('auth/password/verify', data);
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false)
      return {
        success: false,
        message: e.response?.data?.message || 'Please try again!'
      }
    } finally {
      setIsLoading(false)
    }
  }

  const logoutUser = async (): Promise<ApiResponseType<IResetPasswordResponse>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('auth/logout');
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false)
      return {
        success: false,
        message: e.response?.data?.message || 'Please try again!'
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getUserProfile = async () :Promise<ApiResponseType<TUser>> => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.get(`users/profile`);
      setUser(prevState => ({ ...prevState, ...response.data.result }));
      return {
        success: true,
        data: response.data.result,
        message: response.data.message
      }
    } catch (e: any) {
      setIsLoading(false);
      return {
        success: false,
        message: e.response?.data?.message || 'Please try again!'
      }
    } finally {
      setIsLoading(false)
    }
  }

  const contextValue: IAuthContext = {
    registerUser,
    forgetPassword,
    loginUser,
    otpVerification,
    resendOtp,
    resetPassword,
    passwordVerification,
    logoutUser,
    isLoading,
    getUserProfile,
    user,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}
