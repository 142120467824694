import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BusinessSetting from './BusinessSetting';
import AccountBusiness from './AccountBussiness';
import './Setting.scss'


const editSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name cannot be empty. Please provide your first name to update your profile.')
    .matches(/^[a-zA-Z][-' .a-zA-Z0-9]{0,24}$/, 'Invalid characters in name. Please use only letters for your first and last name.')
    .max(25, 'First name not exceed 25 characters'),
  lastName: Yup.string()
    .required('Last name cannot be empty. Please provide your last name to update your profile.')
    .matches(/^[a-zA-Z][-' .a-zA-Z0-9]{0,24}$/, {
      message: 'Invalid characters in name. Please use only letters for your first and last name.',
      excludeEmptyString: true,
    })
    .max(25, 'Last name must not exceed 25 characters'),
});

type TTab = {
  id: number,
  title: string,
  key: string
}

const Setting = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    profileImage: '',
    email: '',
    panNumber: ''
  };

  const [selectTab, setSelectedTab] = useState('business');
  const navigate = useNavigate()
  const {values, errors, touched, handleChange, handleSubmit, setFieldValue, handleBlur, dirty} =
    useFormik({
      initialValues,
      validationSchema: editSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {

      },
    });

  const renderTab = () => {
    switch(selectTab) {
      case 'business':
        return <BusinessSetting
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      case 'account' :
        return <AccountBusiness
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
    }
  }
  const tabs = [
    {
      id: 1,
      title: 'Business',
      key: 'business'
    },
    {
      id: 2,
      title: 'Account',
      key: 'account'
    }
  ];

  const handleTab = (item: TTab) => {
    setSelectedTab(item.key);
    navigate(`/setting/${item.key}`)
  }
  return (
    <div className="common-container">
      <div className="common-title">Setting</div>
      <hr className="m-0 mt-1 hr-wrapper" />
      <div className="setting-right-wrapper">
        <div className="h-100 d-flex flex-column gap-1 setting-left-wrapper">
          {
            tabs.map((item, index) => {
              return (
                <div key={index} className={`setting-tab-wrapper ${selectTab === item.key ? "setting-active-tab" : ''}`} onClick={() => handleTab(item)}>
                  {item.title}
                </div>
              )
            })
          }
        </div>
        <div className="w-100 overflow-auto">
          {renderTab()}
        </div>
      </div>
    </div>
  )
}

export default Setting
