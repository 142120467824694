import React from "react";
import './Button.scss'

type TButton = {
  title: string;
  onClick?: any;
  type?: "button" | "submit" | "reset";
  className?: string;
  prefix?: React.FC | React.ReactNode
}

const Button = ({ title, onClick, type, className, prefix }: TButton) => {
  return (
    <button className={`common-button ${className}`} type={type} onClick={onClick}>
      {title}
    </button>
  )
}

export default Button
