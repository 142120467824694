import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { path } from '../constants';

export const useAuth = () => {
  const authToken = localStorage.getItem("access_token");
  return !!authToken;
};

const PublicRoutes: React.FC = () => {
  const navigate = useNavigate();
  const token = useAuth();

  useEffect(() => {
    if (token) {
      navigate(path.DASHBOARD);
    }
  }, [token, navigate]);

  return token ? <Navigate to={path.LOGIN} replace/> : <Outlet/>;
}
export default PublicRoutes;
