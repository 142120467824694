import { path } from './index';
import DashboardIcon from '../assets/images/dashboard.png'
import DashboardIconActive from '../assets/images/dashboard-active.png'
import PartiesIcon from '../assets/images/parties.png'
import PartiesIconActive from '../assets/images/parties-active.png'
import ItemsIconActive from '../assets/images/item-active.png'
import ItemsIcon from '../assets/images/items.png'
import SalesIconActive from '../assets/images/sales-active.png'
import SalesIcon from '../assets/images/sales.png'
import SettingIcon from '../assets/images/setting.png'
import SettingIconActive from '../assets/images/setting-active.png'


declare type ISidebar = {
  name: string;
  path: string;
  icon: string;
  activeIcon: string
  hasChild: boolean;
  target?: string;
  child?: IChild[]
}

declare type IChild = {
  name: string;
  path: string;
  icon: string;
  activeIcon: string
}

const sideBarPathList: String[] = [
  path.DASHBOARD,
  path.PARTIES,
  path.SALES,
  path.ITEMS,
  path.SETTING
];

const list: ISidebar[] = [
  {
    name: 'Dashboard',
    path: path.DASHBOARD,
    icon: DashboardIcon,
    activeIcon: DashboardIconActive,
    hasChild: false,
  },
  {
    name: 'Client',
    path: path.PARTIES,
    icon: PartiesIcon,
    activeIcon: PartiesIconActive,
    hasChild: true,
  },  {
    name: 'Items',
    path: path.ITEMS,
    icon: ItemsIcon,
    activeIcon: ItemsIconActive,
    hasChild: false,
  },
  {
    name: 'Sales',
    path: path.SALES,
    icon: SalesIcon,
    activeIcon: SalesIconActive,
    hasChild: false,
  },
  {
    name: 'Setting',
    path: path.SETTING,
    icon: SettingIcon,
    activeIcon: SettingIconActive,
    hasChild: false,
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {list, sideBarPathList};
