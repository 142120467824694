import React from 'react';
import { TSVG } from './svgType';

const ArrowIcon: React.FC<TSVG> = (props) => {
  const { className, color="white" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none" className={className}>
      <path d="M1 1.25L4.79289 5.04289C5.18342 5.43342 5.81658 5.43342 6.20711 5.04289L10 1.25" stroke={color}
            strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
export default ArrowIcon;
