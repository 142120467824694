import React from 'react';
import { SearchIcon } from '../../assets/svg';
import './SearchInput.scss'


type TSearchInput = {
  value?: string;
  onChange?: (val: any) => void;
  placeHolder?: string;
  name?: string;
  className?: string;
}

const SearchInput = ({ placeHolder, name, className, onChange, value }: TSearchInput) => {
  return (
    <div className={`search-input-wrapper ${className ? className : ''}`}>
      <SearchIcon />
      <input
        placeholder={placeHolder}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  )
};

export default SearchInput
