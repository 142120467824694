import React, {forwardRef, ReactNode, ForwardedRef} from "react";
import "./Input.scss";

type TInput = {
  placeholder: string;
  name: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent) => void;
  type?: string;
  disabled?: boolean;
  errors?: boolean | string;
  handleFocus?: () => void;
  autoComplete?: string;
  onFocus?: () => void;
  postFixIcon?: ReactNode;
  postFixText?: string;
  onClickPostFixIcon?: any;
  ref?: any;
}

const Input = forwardRef(({ autoComplete, placeholder, name, value,onChange,onBlur,type,disabled, errors = false,handleFocus, postFixText, postFixIcon, onClickPostFixIcon, ...rest }: TInput, ref: ForwardedRef<null>) => {
  return (
    <div className={`input_wrapper ${errors ? "input_error" : ''}`}>
      <input
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        onFocus={handleFocus}
        disabled={disabled}
        autoComplete={autoComplete}
        ref={ref}
        {...rest}
      />
     {postFixText && <div className="input-postfix-text">{postFixText}</div>}
     {postFixIcon && <div className="input-postfix-icon" onClick={() => onClickPostFixIcon(name)}>{postFixIcon}</div>}
    </div>
  )
})

export default Input
