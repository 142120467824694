import React from 'react';

const ShowPasswordEye: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.122 9.88a3.004 3.004 0 0 1 0 4.245 3.004 3.004 0 0 1-4.245 0A3.004 3.004 0 0 1 12 9a3 3 0 0 1 2.122.88"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3 12c0-.659.152-1.311.446-1.912C4.961 6.991 8.309 5 12 5s7.039 1.991 8.554 5.088c.294.601.446 1.253.446 1.912s-.152 1.311-.446 1.912C19.039 17.009 15.691 19 12 19s-7.039-1.991-8.554-5.088A4.346 4.346 0 0 1 3 12v0Z"
      />
    </svg>
  )
}

export default ShowPasswordEye