import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../component/Table';
import Button from '../../component/Button';
import { path } from '../../constants';
import './Items.scss'
import SearchInput from '../../component/SearchInput';
import { data } from './Items';
import EditIcon from '../../assets/images/editing.png'


const Items = () => {
  const [selected, setSelected] = useState('1');
  const navigate = useNavigate();
  const topMenu = [
    {
      key: '1',
      title: 'Stock Value',
      count: 2
    },
    {
      key: '2',
      title: 'Low Stock',
      count: 2
    },
  ]
  const handleClickTop = (key:string) => {
    setSelected(key);
  };

  const handleChangeQTY = (row:any, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation()
  }

  const column = [
    {
      id: 1,
      label: 'Item Name',
      value: 'name',
    },
    {
      id: 2,
      label: 'Item Code',
      value: 'code',
    },
    {
      id: 3,
      label: 'Stock QTY',
      value: 'stoke',
    },
    {
      id: 4,
      label: 'Selling Price',
      value: 'sellingPrice',
    },
    {
      id: 5,
      label: 'Purchase Price',
      value: 'purchasePrice',
    },
    {
      id:  6,
      label: ' ',
      value: '',
      renderCell: (row: any) => {
        return (
          <img src={EditIcon} alt={"edit"} onClick={(e) =>  handleChangeQTY(row, e)} />
        )
      }
    },
  ];


  const handleRedirectDetail = (row: any) => {
    navigate(`${path.ITEMS}/${row.id}`)
  }
  return (
    <div  className="common-container">
      <div className="common-title">Items</div>
      <hr className="m-0 mt-1 hr-wrapper" />
      <div className="d-flex gap-2 mt-4 px-3">
        {
          topMenu.map((item) => {
            return (
              <div className={`w-100 top-container ${selected === item.key ? 'selected-top': ''} `} onClick={() =>handleClickTop(item.key)} key={item.key}>
                <div className="d-flex flex-column">
                  <span>{item.title}</span>
                  <span className="count-text">&#x20B9; {item.count}</span>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="mt-3 d-flex px-3 align-items-center justify-content-between">
        <SearchInput placeHolder="Search Items" />
        <Button title="+ Add Items" className="add-parties-button-wrapper" />
      </div>
      <div className="mt-3 px-3">
        <TableComponent columns={column} data={data} handleOnClickTr={(row) => handleRedirectDetail(row)} />
      </div>
    </div>
  );
};

export default Items;
