import React, { ReactNode, useState } from 'react';
import { InputGroup, Form, Dropdown } from 'react-bootstrap'
import './InputSelect.scss'

type MenuItemsType = {
  label: string;
  value: string;
};

type TInputSelect = {
  prefix?: ReactNode | string;
  placeholder?: string;
  menuItems?: MenuItemsType[];
  dropdownValue?: string;
  onClick?: (e: string) => void;
  placeholderInput?: string;
  isSelect?: boolean;
}

const InputSelect = ({ prefix, placeholder, menuItems, dropdownValue,  onClick, placeholderInput,isSelect = true }: TInputSelect) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleArrow = () => {
    setOpen(!open);
  };

  const handleItemClick = (value: MenuItemsType) => {
    setOpen(false);
    onClick && onClick(value?.label);
  };

  return (
    <InputGroup>
      <div className="search-wrapper">
        <InputGroup.Text>
          {prefix}
        </InputGroup.Text>
        <Form.Control
          placeholder={placeholderInput}
          aria-label="Text input with dropdown button"
        />
      </div>
      {
        isSelect && (
          <Dropdown show={open} onToggle={handleArrow}>
            <Dropdown.Toggle className="input-dropdown">
              {!dropdownValue ? (
                <span>{placeholder}</span>
              ) : (
                <span>{dropdownValue}</span>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {menuItems?.map((item, index) => (
                <Dropdown.Item
                  eventKey={item.label}
                  key={index}
                  onClick={() => handleItemClick(item)}
                >
                  <span className="value">{item.label}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      }
    </InputGroup>
  )
}

export default InputSelect
