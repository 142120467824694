import React, { useRef, useState } from 'react';
import NoImage from '../../assets/images/no-image.jpg';
import Input from '../../component/Input';
import CustomSelect from '../../component/CustomSelect';
import AddressInput from '../../component/AddressInput';
import { Form } from 'react-bootstrap';
import Button from '../../component/Button';

type IBusinessSetting = {
  handleChange: any;
  values: any;
  touched: { [key: string]: boolean };
  errors: { [key: string]: string };
  handleBlur: (e: React.FocusEvent) => void;
  setFieldValue: (field: string, value: any) => void;
}


const BusinessSetting = ({handleChange, values, touched, errors, handleBlur, setFieldValue}: IBusinessSetting) => {
  const editProfileImageRef = useRef<HTMLInputElement | null>(null);
  const [imageError, setImageError] = useState<string>('');
  const [profileImg, setProfileImg] = useState<File | null>(null);
  const handleProfileImageUpdate = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[ 0 ];
    const maxSize = 5 * 1000 * 1000;

    if (file) {
      if (file.size > maxSize) {
        setImageError('The maximum allowed size for the profile picture is 2 MB');
        setProfileImg(null);
        return;
      }

      const allowedFormats = [
        'image/jpeg',
        'image/png',
        'image/jpg',
      ];
      if (!allowedFormats.includes(file.type)) {
        setImageError('Invalid photo format. Please upload an image file (e.g. JPG, JPEG, PNG) for your profile.');
        setProfileImg(null);
        return;
      }

      setImageError('');
      setProfileImg(file);
      setFieldValue('profileImage', file);
      event.target.value = '';
    }
  };
  return (
    <div className="p-3">
      <div className="d-flex gap-2 justify-content-center mt-4 px-3 setting-wrapper">
        <div className="d-flex justify-content-center align-items-center gap-3 mt-2 w-75">
          <div className="d-flex flex-column gap-1">
            <label>Company Logo:</label>
            <input
              ref={editProfileImageRef}
              type="file"
              name="profile-update"
              className="d-none"
              accept=".png, .jpg, .jpeg"
              onChange={handleProfileImageUpdate}
            />
            {profileImg ? (
              <img
                src={URL.createObjectURL(profileImg)  || ''}
                alt="personal-info"
                className="profile-img"
                onClick={() => {
                  if (editProfileImageRef.current) {
                    editProfileImageRef.current.click();
                  }}}
              />
            ) : (
              <div className="name-initials">
                <img
                  src={NoImage}
                  alt="personal-info"
                  className="profile-img"
                  onClick={() => {
                    if (editProfileImageRef.current) {
                      editProfileImageRef.current.click();
                    }}}
                />
              </div>
            )}
            <span className="text-center upload-text"  onClick={() => {
              if (editProfileImageRef.current) {
                editProfileImageRef.current.click();
              }}}>Upload image</span>
          </div>
          <div className="form_sub_wrapper w-50">
            <label>Company Name</label>
            <div>
              <Input
                placeholder="Enter Name"
                name="name"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex mt-4 gap-4 px-3">
        <div className="form_sub_wrapper w-100">
          <label>Mobile Number</label>
          <div>
            <Input
              placeholder="Enter Mobile Number"
              name="mobileNumber"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form_sub_wrapper w-100">
          <label>Email</label>
          <div>
            <Input
              placeholder="Enter Email"
              name="email"
              onChange={handleChange}
              value={values.email}
              onBlur={(e) => handleBlur(e)}
              errors={touched.email && errors.email}
            />
            {touched.email && errors.email ? (
              <p className="error-message">{errors.email}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="px-3 d-flex mt-4  gap-4">
        <div className="form_sub_wrapper w-100">
          <label>PAN Number</label>
          <div>
            <Input
              placeholder="Enter Your PAN Number"
              name="panNumber"
              onChange={handleChange}
              value={values.panNumber}
              onBlur={(e) => handleBlur(e)}
              errors={touched.panNumber && errors.panNumber}
            />
            {touched.panNumber && errors.panNumber ? (
              <p className="error-message">{errors.panNumber}</p>
            ) : null}
          </div>
        </div>
        <div className="form_sub_wrapper w-100">
          <CustomSelect
            name="category"
            placeholder="Select Category"
            options={[]}
            label="Business Type (Select multiple, if applicable)"
            onChange={(name, option) => {
              setFieldValue(name, option.value);
            }}
          />
        </div>
      </div>
      <div  className="px-3 d-flex gap-4 mt-4">
        <div className="form_sub_wrapper w-100">
          <CustomSelect
            name="category"
            placeholder="Select Category"
            options={[]}
            label="Industry Type"
            onChange={(name, option) => {
              setFieldValue(name, option.value);
            }}
          />
        </div>
        <div className="form_sub_wrapper w-100">
          <CustomSelect
            name="category"
            placeholder="Select Category"
            options={[]}
            label="Business Registration Type"
            onChange={(name, option) => {
              setFieldValue(name, option.value);
            }}
          />
        </div>
      </div>
      <div className="px-3 d-flex gap-4 mt-4">
        <div className="form_sub_wrapper w-100">
          <label>Billing Address</label>
          <div>
            <AddressInput
              title="Billing Address"
              placeHolder="Billing Address"
              setFieldValue={setFieldValue}
              values={values}
            />
            {touched.panNumber && errors.panNumber ? (
              <p className="error-message">{errors.panNumber}</p>
            ) : null}
          </div>
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <div className="form_sub_wrapper w-100">
            <label>Are you GST Registered?</label>
            <div className="d-flex gap-3">
              <Form.Check
                type="radio"
                label="Yes"
                name="radioGroup"
              />
              <Form.Check
                type="radio"
                label="No"
                name="radioGroup"
              />
            </div>
          </div>
          <div className="form_sub_wrapper w-100">
            <label>GSTIN</label>
            <div>
              <Input
                placeholder="ex: 29XXXXX9438X1XX"
                name="gstIN"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 d-flex gap-4 mt-4">
        <div className="form_sub_wrapper align-items-center d-flex flex-row justify-content-between w-100">
          <label>Enable TDS</label>
          <Form.Check
            type="switch"
            name="radioGroup"
          />
        </div>
        <div className="form_sub_wrapper align-items-center d-flex flex-row justify-content-between w-100">
          <label>Enable TCS</label>
          <Form.Check
            type="switch"
            name="radioGroup"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button title="Save" className="mt-4 w-25" />
      </div>
    </div>
  )
}

export default BusinessSetting
