import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { ArrowSVG } from '../../../assets/svg';
import Input from '../../../component/Input';
import InputSelect from '../../../component/InputSelect';
import '../Parties.scss'
import CustomSelect from '../../../component/CustomSelect';
import Button from '../../../component/Button';
import AddressInput from '../../../component/AddressInput';


const loginSchema = Yup.object({
  name: Yup.string().required('Please enter your name'),
  openingBalance: Yup.string(),
  mobileNumber: Yup.string(),
  email: Yup.string(),
  type: Yup.string(),
  category: Yup.string(),
  gstIN: Yup.string(),
  panNumber: Yup.string(),
  state: Yup.string(),
  address:  Yup.string(),
  pincode:  Yup.string(),
  city: Yup.string()
});


const AddParties = () => {
  const navigate = useNavigate();

  const {values, setFieldValue, errors, touched, handleChange, handleSubmit, setErrors, handleBlur} = useFormik({
    initialValues: {
      name: '',
      openingBalance: '',
      mobileNumber: '',
      email: '',
      type: '',
      category: '',
      gstIN: '',
      panNumber: '',
      state: '',
      address: '',
      pincode: '',
      city: ''
    },
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {

    },
  });

  const handleOptionChange = (value: string) => {
    setFieldValue('openingBalance', value)
  };
  return (
    <div className="common-container">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex common-title w-100 cursor-pointer align-items-center gap-3" onClick={() => navigate(-1)}>
          <ArrowSVG/> Add Clients
        </div>
        <div className="pe-3 pt-1">
          <Button title="Save" className="py-2" />
        </div>
      </div>
      <hr className="m-0 mt-1 hr-wrapper"/>
      <div>
        <div className="sub-title">
          General Details
        </div>
        <div className="form-wrapper px-3 mt-2">
          <div className="d-flex mb-3 gap-3">
            <div className="form_sub_wrapper w-100">
              <label>Party Name</label>
              <div>
                <Input
                  placeholder="Enter Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  onBlur={(e) => handleBlur(e)}
                  errors={touched.name && errors.name}
                />
                {touched.name && errors.name ? (
                  <p className="error-message">{errors.name}</p>
                ) : null}
              </div>
            </div>
            <div className="form_sub_wrapper w-100">
              <label>Mobile Number</label>
              <div>
                <Input
                  placeholder="Enter Mobile Number"
                  name="mobileNumber"
                  onChange={handleChange}
                  value={values.mobileNumber}
                  onBlur={(e) => handleBlur(e)}
                  errors={touched.mobileNumber && errors.mobileNumber}
                />
                {touched.mobileNumber && errors.mobileNumber ? (
                  <p className="error-message">{errors.mobileNumber}</p>
                ) : null}
              </div>
            </div>
            <div className="form_sub_wrapper w-100">
              <label>Email</label>
              <div>
                <Input
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  onBlur={(e) => handleBlur(e)}
                  errors={touched.email && errors.email}
                />
                {touched.email && errors.email ? (
                  <p className="error-message">{errors.email}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <InputSelect
              menuItems={[{label: 'To Collect', value: 'toCollect'},{label: 'To Pay', value: 'toPay'}]}
              placeholder="Select"
              placeholderInput="0"
              prefix="₹"
              onClick={handleOptionChange}
              dropdownValue={values.openingBalance}
            />
          </div>
          <hr className="hr-wrapper" />

          <div className="d-flex gap-3">
            <div className="form_sub_wrapper w-100">
              <CustomSelect
                value={values.type}
                name="type"
                placeholder="Select Option"
                options={[]}
                label="Party Type"
                onChange={(name, option) => {
                  setFieldValue(name, option.value);
                }}
              />
              {touched.type && errors.type ? (
                <p className="error-message">{errors.type}</p>
              ) : null}

            </div>
            <div className="form_sub_wrapper w-100">
              <CustomSelect
                value={values.category}
                name="category"
                placeholder="Select Category"
                options={[]}
                label="Party Category"
                onChange={(name, option) => {
                  setFieldValue(name, option.value);
                }}
              />
              {touched.category && errors.category ? (
                <p className="error-message">{errors.category}</p>
              ) : null}
            </div>
            <div className="form_sub_wrapper w-100">
              <label>GSTIN</label>
              <div>
                <Input
                  placeholder="ex: 29XXXXX9438X1XX"
                  name="gstIN"
                  onChange={handleChange}
                  value={values.gstIN}
                  onBlur={(e) => handleBlur(e)}
                  errors={touched.gstIN && errors.gstIN}
                />
                {touched.gstIN && errors.gstIN ? (
                  <p className="error-message">{errors.gstIN}</p>
                ) : null}
              </div>

            </div>
            <div className="form_sub_wrapper w-100">
              <label>PAN Number</label>
              <div>
                <Input
                  placeholder="Enter Client PAN Number"
                  name="panNumber"
                  onChange={handleChange}
                  value={values.panNumber}
                  onBlur={(e) => handleBlur(e)}
                  errors={touched.panNumber && errors.panNumber}
                />
                {touched.panNumber && errors.panNumber ? (
                  <p className="error-message">{errors.panNumber}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-title mt-3">
        Address
      </div>
      <div className="form-wrapper px-3 mt-2">
        <div className="d-flex mb-3 gap-3">
          <div className="form_sub_wrapper w-100">
            <label>Billing Address</label>
            <div>
              <AddressInput
                title="Billing Address"
                placeHolder="Billing Address"
                setFieldValue={setFieldValue}
                values={values}
              />
              {touched.panNumber && errors.panNumber ? (
                <p className="error-message">{errors.panNumber}</p>
              ) : null}
            </div>
          </div>
          <div className="form_sub_wrapper w-100">
            <label>Shipping Address</label>
            <div>
              <AddressInput
                title="Shipping Address"
                placeHolder="Shipping Address"
                setFieldValue={setFieldValue}
                values={values}
              />
              {touched.mobileNumber && errors.mobileNumber ? (
                <p className="error-message">{errors.mobileNumber}</p>
              ) : null}
            </div>
          </div>
        </div>
        <hr className="m-0 mt-1 hr-wrapper"/>
        <div className="mt-4 d-flex gap-3">
          <div className="form_sub_wrapper">
            <label>Credit Period</label>
            <InputSelect
              placeholderInput="30"
              prefix="Days"
              isSelect={false}
            />
          </div>
          <div className="form_sub_wrapper">
            <label>Credit Limit</label>
            <InputSelect
              placeholderInput="0"
              prefix="₹"
              isSelect={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParties;
