import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { path, sidebar } from '../../constants';
import Collapse from 'react-bootstrap/Collapse';
import './Sidebar.scss';

declare type IChild = {
  name: string;
  path: string;
  icon: string;
  activeIcon: string;
}
declare type ISidebar = {
  name: string;
  path: string;
  icon: string;
  activeIcon: string;
  hasChild: boolean;
  target?: string;
  child?: IChild[]
}

type SidebarPropsType = {
  classOfNavBar: string,
  hideNavbarFooter: () => void
}

type IChildItem = {
  item: ISidebar;
  classOfNavBar: string;
  activeTab: string;
}

const ChildItem: React.FC<IChildItem> = (props) => {
  const { item, classOfNavBar, activeTab } = props;
  const [open, setOpen] = useState<boolean>(false);
  const childPath = item?.child && item?.child.reduce((accumulator: string[], currentValue: IChild) => {
    return [...accumulator, currentValue.path];
  }, []);
  const isSelectedChild = childPath ? childPath.includes(activeTab) : false;

  useEffect(() => {
    setOpen(isSelectedChild);
  }, [activeTab, isSelectedChild])

  const handleArrowClickEvent = (event: React.MouseEvent<HTMLAnchorElement> | undefined) => {
    if (event) event.preventDefault();
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <a href={path.DASHBOARD} className={`navbar1 ${props.classOfNavBar} ${isSelectedChild ? 'active' : ''}`}
         onClick={handleArrowClickEvent}>
        <div>
          {isSelectedChild ? <img src={item.activeIcon} alt="icon" /> : <img src={item.icon} alt="icon" />}
        </div>
        {!(props.classOfNavBar) &&
				<div className={`d-flex align-items-center navbar-items ${props.classOfNavBar}`}>
            <span className="">
              {item.name}
            </span>
					<div className="apps-arrow-wrapper">
						<img
							className={open ? 'apps ms-2 open-apps' : 'ms-2 apps'}
							src="/Assets/IMAGES/apps-arrow.svg"
							style={{ width: '12px', height: '12px' }}
							alt="apps-arrow"
						/>
					</div>
				</div>
        }
      </a>
      <Collapse in={open}>
        <div className={`apps-nav ${classOfNavBar}`}>
          {item.child?.map((item, index) => {
            const isSelected = activeTab === item.path;
            return (
              <Link to={item.path} className={` navbar1 ${isSelected ? 'active' : ''}`} key={index}>
                <div>
                  {isSelected ? <img src={item.activeIcon}  alt="icon" /> : <img src={item.icon}  alt="icon" />}
                </div>
                <span className={`navbar-items ${classOfNavBar}`}>
                  {item.name}
                </span>
              </Link>
            );
          })}
        </div>
      </Collapse>
    </React.Fragment>
  );
};

export default function Sidebar(props: SidebarPropsType) {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('dashboard');



  useEffect(() => {
    const pathnameSegments = location.pathname.split('/');
    const path = pathnameSegments[1];
    if (sidebar.sideBarPathList.includes(`/${path}`)) {
      setActiveTab(`/${path}`);
    }
  }, [location]);

  return (
    <div className={`navbar-content ${!!props.classOfNavBar && 'hide'}`}>
      <div className="d-flex mb-3 ps-2 pt-1 gap-3 align-items-center">
        <div className="profile-wrapper">
          A
        </div>
        <span className="user-name">Admin</span>
      </div>
      <hr className="mb-3 mt-0 border-white" />

      <div className="content">
        {sidebar.list.map((item, index) => {
          const isSelected = activeTab === item.path;
          if (item.hasChild && item?.child) {
            return <ChildItem classOfNavBar={props.classOfNavBar} item={item} activeTab={activeTab} key={index} />
          }

          return (
            <Link to={item.path} className={` navbar1 ${isSelected ? 'active' : ''}`} target={item.target} key={index}>
              <div  className="d-flex align-items-center justify-content-center">
                {isSelected ? <img src={item.activeIcon} alt="icon" /> : <img src={item.icon} alt="icon" />}
              </div>
              <span className={`navbar-items ${props.classOfNavBar}`}>{item.name}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
