import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../component/Table';
import Button from '../../component/Button';
import { path } from '../../constants';
import './Parties.scss'
import SearchInput from '../../component/SearchInput';
import { data } from './PartiesData';


const Parties = () => {
  const [selected, setSelected] = useState('1');
  const navigate = useNavigate();
  const topMenu = [
    {
      key: '1',
      title: 'All Parties',
      count: 2
    },
    {
      key: '2',
      title: 'To Collect',
      count: 2
    },
    {
      key: '3',
      title: ' To Pay',
      count: 2
    },
  ]
  const handleClickTop = (key:string) => {
    setSelected(key);
  };

  const column = [
    {
      id: 1,
      label: 'Party Name',
      value: 'name',
    },
    {
      id: 2,
      label: 'Category',
      value: 'category',
    },
    {
      id: 3,
      label: 'Mobile Number',
      value: 'number',
    },
    {
      id: 4,
      label: 'Party type',
      value: 'type',
    },
    {
      id: 5,
      label: 'Balance',
      value: 'balance',
    },
  ];


  const handleRedirectDetail = (row: any) => {
    navigate(`${path.PARTIES}/${row.id}`)
  }
  return (
    <div  className="common-container">
      <div className="common-title">Client</div>
      <hr className="m-0 mt-1 hr-wrapper" />
      <div className="d-flex gap-2 mt-4 px-3">
        {
          topMenu.map((item) => {
           return (
             <div className={`w-100 top-container ${selected === item.key ? 'selected-top': ''} `} onClick={() =>handleClickTop(item.key)} key={item.key}>
               <div className="d-flex flex-column">
                 <span>{item.title}</span>
                 <span className="count-text">{item.count}</span>
               </div>
             </div>
           )
          })
        }
      </div>
      <div className="mt-3 d-flex px-3 align-items-center justify-content-between">
        <SearchInput placeHolder="Search Parties" />
        <Button title="+ Add Parties" className="add-parties-button-wrapper" onClick={() => navigate(path.ADD_PARTIES)} />
      </div>
      <div className="mt-3 px-3">
        <TableComponent columns={column} data={data} handleOnClickTr={(row) => handleRedirectDetail(row)} />
      </div>
    </div>
  );
};

export default Parties;
