import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './AddressInput.scss';
import Button from '../Button';
import CustomSelect from '../CustomSelect';
import Input from '../Input';

type IAddressInput = {
  placeHolder: string;
  title: string;
  setFieldValue: any;
  values: any
}
const AddressInput = ({
  placeHolder,
  title,
  setFieldValue,
  values
}: IAddressInput) => {

  const [modal, setModal] = useState(false);


  const handleModal = () => {
    setModal(true)
  }
  return (
    <>
    <div className="address-wrapper" onClick={handleModal}>
      <span className="placeholder-wrapper">{placeHolder}</span>
    </div>
      <Modal
        centered
        show={modal}
        onHide={() => setModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-wrapper">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form_sub_wrapper">
              <label>Street Address</label>
              <textarea
                className="w-100 border p-2 rounded shadow-sm"
                placeholder="Enter Street Address"
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className="d-flex mt-2 mb-3 gap-3">
              <div className="form_sub_wrapper w-100">
                <CustomSelect
                  value={values?.state}
                  name="state"
                  placeholder="Select State"
                  options={[]}
                  label="State"
                  onChange={(name, option) => {
                    setFieldValue(name, option.value);
                  }}
                />
              </div>
                <div className="form_sub_wrapper w-100">
                  <label>Pincode</label>
                  <Input
                    placeholder="Enter Pincode"
                    name="pincode"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    value={values.pincode}
                  />
              </div>
            </div>
            <div className="form_sub_wrapper w-100 mt-2">
              <label>City</label>
              <Input
                placeholder="Enter City"
                name="city"
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
                value={values.city}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="w-auto" title="Save" />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddressInput
