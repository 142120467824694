export const data = [
  {
    name: 'test Item',
    code: 'fdgs',
    stoke: 1,
    sellingPrice: 22,
    purchasePrice: 22,
    id: 1
  },
  {
    name: 'Test Item 3',
    code: 'fdgnn',
    stoke: 4,
    sellingPrice: 33,
    purchasePrice: 22,
    id: 2
  }
];
