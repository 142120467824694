import axios from 'axios';
import { path } from '../constants';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refresh_token');
    if (error.response.data.status === 404) {
      // window.location.href = path.PAGE_NOT_FOUND;
    } else if (
      refreshToken &&
      error.response.data.status === 400 &&
      error.response?.data?.customErrorNumber === 100006 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const headers = {
          Authorization: `Bearer ${refreshToken}`,
        };

        const res = await axios.get(`${BASE_URL}/auth/refresh_token`, {
          headers,
        });

        localStorage.setItem("access_token", res.data.result.access_token);
        localStorage.setItem("refresh_token", res.data.result.refresh_token);
        originalRequest.headers.Authorization = `Bearer ${res.data.result.access_token}`;
        return axios(originalRequest);

      } catch (error) {
        localStorage.clear();
        window.location.href = path.LOGIN;
        console.log("Error refreshing access token:", error);
      }
    }
    return Promise.reject(error);
  }
);


export default axiosInstance;
