const ROOT: string = '/';
const LOGIN: string = '/login';
const REGISTRATION: string = '/register';
const FORGOT_PASSWORD: string = '/forgot-password';
const DASHBOARD: string = '/dashboard';
const PARTIES: string = '/parties';
const ITEMS: string = '/items';
const SALES: string = '/sales';
const OTP_VERIFICATION: string = '/verify';
const RESET_PASSWORD: string = '/updatepassword';
const ADD_PARTIES: string = '/add-parties';
const SETTING: string = '/setting';
const PARTIES_DETAIL: string = '/parties/:id?';

const path = {
  ROOT,
  LOGIN,
  DASHBOARD,
  REGISTRATION,
  FORGOT_PASSWORD,
  OTP_VERIFICATION,
  RESET_PASSWORD,
  PARTIES,
  ITEMS,
  SALES,
  ADD_PARTIES,
  SETTING,
  PARTIES_DETAIL
};

export default path;
