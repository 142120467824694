import * as React from 'react'

type ISVG = {
  color?: string;
}

const ApprovedTick = (props: ISVG) => {
  const { color = "#0CAB7C", ...rest } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      {...rest}
    >
      <path
        fill={color}
        d="M18.71 8.18a.999.999 0 0 0-1.42 0l-7.45 7.46-3.13-3.14a1.02 1.02 0 1 0-1.42 1.47l3.84 3.84a1 1 0 0 0 1.42 0l8.16-8.16a.999.999 0 0 0 0-1.47Z"
      />
    </svg>
  )
}
export default ApprovedTick
