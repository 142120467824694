import React, {useCallback, useState} from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { path } from '../constants';

import CustomToast from '../component/CustomToast';
import { useCustomToast } from '../context/CustomToastContext';
import SideBar from "../component/Sidebar";


export const useAuth = () => {
  const authToken = localStorage.getItem("access_token");
  return !!authToken;
};

export const PrivateRoutes: React.FC = () => {
  const token = useAuth();
  const { message } = useCustomToast();
  const [classOfNavBar, setClassOfNavBar] = useState("");
  const hideNavbarFooter = useCallback(() => {
    setClassOfNavBar((prevClass) => (prevClass ? "" : "hide-navbar-footer"));
  }, [])
  return token ?
    <>
      {message.text && <CustomToast />}
      <div className="sub-container full-height">
        <SideBar classOfNavBar={classOfNavBar} hideNavbarFooter={hideNavbarFooter} />
        <Outlet />
      </div>
    </>
    :
    <Navigate to={path.LOGIN} replace />;
};
export default PrivateRoutes;
