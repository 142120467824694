import React, { useEffect, useRef, useState } from 'react';
import ArrowIcon from '../../assets/svg/ArrowSVG';
import './select.scss';

export type IOption = {
  id?: number;
  value: string;
  label: string;
  disabled?: boolean;
}

type ICustomSelectProps = {
  className?: string;
  name: string;
  value?: IOption | string | undefined;
  onChange?: (key: string, selectedOption: IOption) => void;
  options: IOption[];
  defaultValue?: IOption;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  isSearchable?: boolean;
  disabled?: boolean;
  isCheckIcon?: boolean;
  element?: any;
  label?: string;
  placeholder: string;
};

const CustomSelect: React.FC<ICustomSelectProps> = ({
 name,
 className,
 value,
 placeholder,
 options,
 placement,
 disabled,
 defaultValue,
 element,
 label,
  onChange,

  }) => {
  const [selectedOption, setSelectedOption] = useState<IOption>({
    label: '',
    value: '',
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const handleOptionSelect = (name: string, option: IOption) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    if (onChange) {
      onChange(name as string, option);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    setIsDropdownOpen(false);
    if (options) {
      const matchedOption = options.find(option => option.value === value);
      setSelectedOption(matchedOption as IOption);
    }
  }, [value, options]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-100 d-flex flex-column ">
      {label && <div className="custom-select-label">{label}</div>}
      <div
        className={`custom-select-container ${disabled ? 'select-disable' : ''} ${isDropdownOpen ? 'open' : ''} ${className || ''}`}
        ref={selectRef}>
        <div
          className={`selected-value ${disabled ? 'select-disable' : ''} ${isDropdownOpen ? 'active' : ''}`}
          onClick={() => {
            if (!disabled) {
              setIsDropdownOpen(!isDropdownOpen);
            }
          }}
        >
          {selectedOption ? (
            <span>{selectedOption?.label}</span>
          ) : (
            defaultValue ?
              <span>{defaultValue.label}</span>
              :
              <span className="placeholder-wrapper">{placeholder || 'Select an option'}</span>
          )}
          {element ? element : <ArrowIcon color="#000000"/>}
        </div>
        {options && options.length > 0 ? (
          <ul className={`options-wrapper ${placement || ''}`}>
            {options?.map((option, index) => (
              <li
                key={`value-${index}`}
                onClick={option.disabled ? () => {
                } : () => handleOptionSelect(name, option)}
                className={selectedOption === option ? 'selected-label' : option.disabled ? 'select-disable' : ''}
              >
                {option.label}
              </li>
            ))}
          </ul>
        ) : (
          <ul className={`options-wrapper ${placement || ''}`}>
            <li className="d-flex justify-content-center">No Data</li>
          </ul>
        )}
      </div>
    </div>
  );
};
export default CustomSelect;
