import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from '../Loader';
import './Table.scss';

type ITableComponent = {
  title?: string;
  data: any[];
  columns: any[];
  children?: JSX.Element;
  totalCount?: number;
  fetchMore?: (pageSize: number, page: number) => void;
  isSearch?: boolean;
  isGenerate?: boolean;
  selectUser?: number[];
  setSelectUsers?: (
    previousState: (previousState: number[]) => number[]
  ) => void;
  onFilterChange?: any
  customBodyCss?: string;
  loading?: boolean;
  handleModal?: (value: string) => void;
  handleOnClickTr?: (row: any) => void;
  isServerSidePagination?: boolean;
  isPagination?: boolean;
  activePage?: number;
  perPageSize?: number
}

type IColumn = {
  id: string | number;
  key: string;
  value: string;
  label?: string;
  renderCell?: (data: unknown, index: number | string) => React.ReactNode;
  labelCell?: () => React.ReactNode;
  isSort?: boolean;
  sorter?: (a: unknown, b: unknown) => number;
}

type ITR = {
  title: string;
  sno: number;
  columns?: IColumn[];
  rowData: any;
  handleOnClickEvent?: (rowData: unknown) => void;
  selectUser?: number[];
  handleSetSelectUser?: (values: number[]) => void;
}

const TR: React.FC<ITR> = (props) => {
  const { columns, rowData, sno, handleOnClickEvent = () => { } } = props;

  return (
    <tr className="text-center" onClick={() => handleOnClickEvent(rowData)}>
      {columns?.map((column, index) => {
        return (
          <td className="align-middle  text-center" key={index} >
            {typeof column.renderCell === 'function' ? column.renderCell(rowData, sno) : column.value ? rowData?.[column?.value] : '-'}
          </td>
        );
      })}
    </tr>
  );
};

const TableComponent: React.FC<ITableComponent> = ({
  title = '',
  data = [],
  columns = [],
  children,
  customBodyCss = '',
  totalCount,
  fetchMore,
  onFilterChange,
  loading,
  isSearch,
  handleOnClickTr,
  isServerSidePagination = false,
  // isPagination = true,
  activePage,
  perPageSize = 10
}) => {
  const [perPageData, setPerPageData] = useState<number>(perPageSize);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pages, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<any>();
  const [isSort, setIsSort] = useState<boolean>(false);

  useEffect(() => {
    if (totalCount && totalCount >= 0) {
      const totalPages = Math.ceil(totalCount / perPageData);
      setTotalPages(totalPages);
    } else {
      setTotalPages(1)
    }
  }, [perPageData, totalCount]);

  useEffect(() => {
    if (activePage) {
      setPage(Number(activePage))
    } else {
      setPage(1)
    }
  }, [isSearch, activePage])

  useEffect(() => {
    if (!data.length && totalPages > 1 && pages !== 1) {
      setPage(Number(pages))
      if (fetchMore) {
        fetchMore(perPageData, pages)
      }
    } else if (!data.length && pages === 1 && totalPages > 1) {
      if (fetchMore) {
        fetchMore(perPageData, 1)
      }
    } else {
      setPage(1)
    }
  }, [!data.length])


  const onSort = (column: IColumn) => (event:React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSort(!isSort);
    setFilter({ ...filter, sortBy: column.value, orderBy: isSort ? 'ASC' : 'DESC' })
    onFilterChange?.({ ...filter, sortBy: column.value, orderBy: isSort ? 'ASC' : 'DESC' })
  };

  const handleSort = async (sorter: (a: unknown, b: unknown) => number, column: IColumn) => {
    setIsSort(!isSort)
    if (sorter) {
      return recordsToDisplay.sort(sorter)
    } else {
      return recordsToDisplay.sort((a, b) => {
        const nameA = a[column.value];
        const nameB = b[column.value];
        if (isSort) {
          if (nameA > nameB) {
            return 1
          } else {
            return -1
          }
        } else {
          if (nameA < nameB) {
            return 1
          } else {
            return -1
          }
        }
      })
    }
  }

  const recordsToDisplay = useMemo(() => {
    if (isServerSidePagination) {
      return data;
    }
    const startIndex = (Number(pages) - 1) * Number(perPageData);
    const endIndex = startIndex + Number(perPageData);
    if (data) {
      return data?.slice(startIndex, endIndex);
    } else return []

  }, [
    data,
    isServerSidePagination,
    pages,
    perPageData,
  ]);

  return (
    <Row className={`custom-table m-0 ${customBodyCss}`}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        {children}
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="position-relative p-0">
        {
          loading &&
            <Loader />
        }
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {columns?.map((column: IColumn, index: number) => {
                  return (
                    <th key={index}>
                      <div className="d-flex align-items-center text-nowrap justify-content-center">
                        {column.labelCell ? (
                          column.labelCell()
                        ) : (
                          column.label
                        )}
                        {
                          column.isSort && (
                            <span onClick={isServerSidePagination ? onSort(column) : () => {
                              if(column.sorter) {
                                handleSort(column.sorter, column)
                              }
                            }}
                              className={`cursor-pointer ms-1 ${(isSort && (column?.value === filter?.sortBy)) && 'opacity-50'}`}>
                              <img src="/Assets/IMAGES/sort-icon.png" alt="sort-img" />
                            </span>
                          )
                        }
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {!!data.length ? (
                (recordsToDisplay || [])?.map((row: unknown, index: number) => {
                  return (
                    <TR
                      title={title}
                      sno={index + 1}
                      columns={columns}
                      rowData={row}
                      key={index}
                      handleOnClickEvent={handleOnClickTr}
                    />
                  );
                })
              ) : (
                !loading && (
                  <tr>
                    <td colSpan={columns.length + 1}>
                      <div className="no-data mt-2">No data found</div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
};

export default TableComponent;
