import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SearchInput from '../../../component/SearchInput';
import { data } from '../Items';
import { path } from '../../../constants';
import { ArrowSVG } from '../../../assets/svg';
import Button from '../../../component/Button';
import './ItemsDetail.scss'

const ItemSDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(data[0]);
  const handleClickParties = (item: any) => {
    setActiveItem(item)
    navigate(`${path.ITEMS}/${item.id}`)
  }
  return (
    <div className="common-container">
      <div className="partiesDetail-wrapper">
        <div className="partiesDetail-left-wrapper">
          <SearchInput placeHolder="Search Parties" />
          <div className="mt-3 d-flex flex-column gap-3">
            {
              data.map((item) =>
                <div className={`parties-list ${params?.id === item?.id.toString() ? 'active-parties' : ''}`} onClick={() =>handleClickParties(item)}>
                  {item.name}
                  <span>
                   stoke value: {item.stoke} &#x20B9;
                  </span>
                </div>
              )
            }
          </div>
        </div>
        <div className="w-100">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex common-title w-100 cursor-pointer align-items-center gap-3" onClick={() => navigate(-1)}>
              <ArrowSVG/> {activeItem.name}
            </div>
            <div className="pe-3 pt-1">
              <Button title="Save" className="py-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemSDetail
