import React from 'react';
import { useCustomToast } from '../../context/CustomToastContext';
import { FailIcon, CheckIcon } from '../../assets/svg/index';
import './CustomToast.scss'

export type IMessage = {
  success: boolean,
  text: string,
  show: boolean
}

const CustomToast = () => {
  const { message: { success, text, show }, setMessage } = useCustomToast();
  return (
    <div
      className={`toast-main-container ${!show ? 'toast' : 'toast-exit-active'} ${success && 'toast-success'}`}
      onClick={() => setMessage({
        text: '',
        success: false,
        show: false
      })}>
      <div className='d-flex align-items-center'>
        {success ? <div className="success-icon-image"> <CheckIcon color="#3bd378" /></div> : <FailIcon color='#FF0000' height={42} width={42}/>}
      </div>
      <p>
        {text}
      </p>
    </div>
  );
}

export default CustomToast;
