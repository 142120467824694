import React from 'react';

const SearchIcon: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        fill="#AFB4C0"
        d="m15.803 12.622-1.553-1.59a2.31 2.31 0 0 0-2.55-.427l-.675-.675a5.25 5.25 0 1 0-1.058 1.057l.668.668a2.25 2.25 0 0 0 .398 2.595l1.59 1.59a2.25 2.25 0 0 0 3.18 0 2.248 2.248 0 0 0 0-3.218Zm-6.36-3.18a3.75 3.75 0 1 1-5.297-5.31 3.75 3.75 0 0 1 5.296 5.31Zm5.302 5.303a.75.75 0 0 1-1.065 0l-1.59-1.59a.75.75 0 0 1 .244-1.23.751.751 0 0 1 .821.165l1.59 1.59a.75.75 0 0 1 0 1.065Z"
      />
    </svg>
  );
};
export default SearchIcon;
