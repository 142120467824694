export const data = [
  {
    name: 'test',
    category: 'abc',
    number: '12132343545',
    type: 'sale',
    balance: 222,
    id: 1
  },
  {
    name: 'Test 3',
    category: 'Okay',
    number: '12132343545',
    type: 'sale',
    balance: 12,
    id: 2
  }
];
