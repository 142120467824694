import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { path } from "../constants";
import Loader from "../component/Loader";
import Parties from '../container/Parties';
import Items from '../container/Items';
import Sales from '../container/Sales';
import AddParties from '../container/Parties/Add Parties';
import Setting from '../container/Setting';
import PartiesDetail from '../container/Parties/partiesDetail';
import ItemSDetail from '../container/Items/Item.sDetails';

const Login = lazy(() => import("../container/Login"));
const ForgotPassword = lazy(() => import("../container/ForgetPassword"));
const ResetPassword = lazy(() => import("../container/ResetPassword"));
const RegistrationPage = lazy(() => import("../container/Registration"));
const Dashboard = lazy(() => import("../container/Dashboard"));



const AppRoutes = () => {
  const LandingPage = () => {
    return <Navigate to={path.LOGIN} />;
  };
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path={path.DASHBOARD} element={<Dashboard />} />
          <Route path={path.PARTIES} element={<Parties />} />
          <Route path={path.ITEMS} element={<Items />} />
          <Route path={`${path.ITEMS}/:id?`} element={<ItemSDetail />} />
          <Route path={path.SALES} element={<Sales />} />
          <Route path={path.ADD_PARTIES} element={<AddParties />} />
          <Route path={`${path.PARTIES}/:id?`} element={<PartiesDetail />} />
          <Route path={`${path.SETTING}/:tab?`} element={<Setting />} />
        </Route>
        <Route element={<PublicRoutes />}>
          <Route path={path.ROOT} element={<LandingPage />} />
          <Route path={path.LOGIN} element={<Login />} />
          <Route path={path.REGISTRATION} element={<RegistrationPage />} />
          <Route path={path.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={path.RESET_PASSWORD} element={<ResetPassword />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
export default AppRoutes;
