import React from 'react';

const Sales = () => {
  return (
    <div className="common-container">
      Sales
    </div>
  )
}

export default Sales
