import React from 'react';
import Input from '../../component/Input';
import Button from '../../component/Button';


type TAccountBusiness = {
  handleChange: any;
  values: any;
  touched: { [key: string]: boolean };
  errors: { [key: string]: string };
  handleBlur: (e: React.FocusEvent) => void;
  setFieldValue: (field: string, value: any) => void;
}

const AccountBusiness = ({handleChange, values, touched, errors, handleBlur, setFieldValue}: TAccountBusiness) => {
  return (
    <div className="p-3">
      <div className="d-flex mt-4 gap-4 px-3">
        <div className="form_sub_wrapper w-100">
          <label>Name</label>
          <div>
            <Input
              placeholder="Enter Your Name"
              name="name"
              onChange={handleChange}
              value={values.name}
              onBlur={(e) => handleBlur(e)}
              errors={touched.name && errors.name}
            />
            {touched.name && errors.name ? (
              <p className="error-message">{errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="form_sub_wrapper w-100">
          <label>Mobile Number</label>
          <div>
            <Input
              placeholder="Enter Mobile Number"
              name="mobileNumber"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form_sub_wrapper w-100">
          <label>Email</label>
          <div>
            <Input
              placeholder="Enter Email"
              name="email"
              onChange={handleChange}
              value={values.email}
              onBlur={(e) => handleBlur(e)}
              errors={touched.email && errors.email}
            />
            {touched.email && errors.email ? (
              <p className="error-message">{errors.email}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button title="Save" className="mt-4 w-25" />
      </div>
    </div>
  )
}

export default AccountBusiness
